<template>
  <common :title="title">
    <!-- 状态按钮 -->
    <template #operate-btn>
      <!-- <div class="operate-r">
        <div class="operate-rl">
          <div
            class="opera"
            @click="dialog = true"
            v-if="!detailData.isCheckIn"
          >
            去签到
          </div>
        </div>
      </div> -->
    </template>
    <div class="detailData">
      <div class="item-l">
        <div class="active-Picture">
          <img :src="detailData.activityPicture" alt="" />
        </div>
        <div class="rules">活动规则</div>
        <p>
          <span>报名开始时间：</span>
          <span> {{ detailData.applyStartDate }}</span>
        </p>
        <p>
          <span>活动开始时间：</span>
          <span> {{ detailData.startDate }}</span>
        </p>
        <p>
          <span>报名人数：</span>
          <span class="number">
            <span>{{ detailData.applyUserCount }}</span
            >/{{
              detailData.limitCount == "0" ? "不限制" : detailData.limitCount
            }}</span
          >
        </p>
        <p>
          <span>活动地点：</span>
          <span> {{ detailData.activityAddress }}</span>
        </p>
      </div>
      <div class="item-r">
        <div class="activityDetail-c" v-if="detailData.hasFeedback == 1">
          <div class="title">活动回顾</div>
          <div class="body">
            <v-h5Mtml :content="detailData.feedback"></v-h5Mtml>
          </div>
          <div class="fbVideoUrl" v-if="detailData.fbVideoUrl">
            <video :src="detailData.fbVideoUrl" controls="controls"></video>
          </div>
          <template v-if="detailData.fbImageUrls">
            <div
              class="fbImageUrls"
              v-for="(item, index) in detailData.fbImageUrls
                ? detailData.fbImageUrls.split(',')
                : []"
              :key="index"
            >
              <img
                :src="item"
                mode="widthFix"
                @click="bannerClick(detailData.fbImageUrls.split(','), index)"
              />
            </div>
          </template>
        </div>
        <div class="activityDetail-c" v-else>
          <div class="title">活动内容</div>
          <div class="body">
            <v-h5Mtml :content="detailData.content"></v-h5Mtml>
          </div>
          <div class="fbVideoUrl" v-if="detailData.videoUrl">
            <video
              id="video"
              :src="detailData.videoUrl"
              controls="controls"
            ></video>
          </div>
          <template
            v-if="detailData.imageUrls && detailData.imageUrls != undefined"
          >
            <div
              class="fbImageUrls"
              v-for="(item, index) in detailData.imageUrls
                ? detailData.imageUrls.split(',')
                : []"
              :key="index"
            >
              <img :src="item" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <custom-dialog title="提示" v-model="dialog" @confirm="handleCheckIn">
      <div class="dialog-content">
        <div class="badge"><van-icon name="success" /></div>
        <p>是否确定签到？</p>
      </div>
    </custom-dialog>
  </common>
</template>

<script>
import { getActivityDetail, signInOrOut, allographUrl } from "./api";
import common from "../components/common.vue";
import customDialog from "../components/customDialog.vue";
import Vue from "vue";
import { Icon } from "vant";
import { mapState } from "vuex";
Vue.use(Icon);
export default {
  name: "detailData",
  components: {
    common,
    customDialog,
  },
  props: {},
  data() {
    return {
      dialog: false,
      title: "",
      detailData: {},
      searchParams: {
        activityId: "",
        // userId: "1763412296209608705",
      },
      scheduleId: "",
      applyId: "",
    };
  },
  created() {},
  mounted() {
    const { activityId, activityName, scheduleId, applyId } = this.$route.query;
    if (![null, undefined, ""].includes(activityId)) {
      this.title = activityName;
      this.scheduleId = scheduleId;
      this.applyId = applyId;
      this.searchParams.activityId = activityId;
      this.getActivityDetail(activityId);
    }
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    //获取列表
    getActivityDetail() {
      const params = {
        ...this.searchParams,
        userId: this.userId,
        activityId: this.searchParams.activityId,
        applyId: this.applyId,
      };
      this.$axios
        .get(getActivityDetail, {
          params,
        })
        .then((res) => {
          if (res.code == 200) {
            this.detailData = res.data;
          }
        });
    },
    //去签到
    async handleCheckIn() {
      let params = {
        scheduleId: this.scheduleId,
        userId: this.userId || "1777286254038163457",
        activityId: this.searchParams.activityId,
        type: 1,
      };
      let res = await this.$axios.post(signInOrOut, this.$qs.stringify(params));
      if (res.code === 200) {
        this.$toast({ message: "签到成功", duration: 1000 });
        setTimeout(() => {
          this.$router.replace({
            name: "myCheckIn",
          });
        }, 1000);
      }
    },
  },
};
</script>

<style scoped lang="less">
.operate-r {
  width: 120px;
  height: 50px;
  .operate-rl {
    width: 100%;
    height: 100%;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    border-radius: 10px;
  }
}

.detailData {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding: 26px 0 32px 0;
  box-sizing: border-box;
  .setion-content {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .item-l {
    width: 327px;
    flex-shrink: 0;
    margin-right: 60px;
    box-sizing: border-box;
    .active-Picture {
      width: 100%;
      height: 229px;
      border-radius: 4px;
      overflow: hidden;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .rules {
      margin: 13px 0 13px 0;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 20px;
      color: #2f3a4f;
    }
    p {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 15px;
        box-sizing: border-box;
      }
      span {
        font-weight: 400;
        font-size: 18px;
        color: #666666;
        line-height: 25px;
        text-align: left;
        &:first-child {
          width: 126px;
        }
      }
      .number {
        color: #1874fd;
        span {
          color: inherit;
        }
      }
    }
  }
  .item-r {
    flex: 1;
    height: calc(100% - 53px);
    padding-bottom: 20px;
    overflow-y: auto;
    box-sizing: border-box;
    .activityDetail-c {
      .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 28px;
        color: #2f3a4f;
        line-height: 28px;
        text-align: left;
        font-style: norma;
      }
      .fbImageUrls {
        width: 100%;
        image {
          width: 100%;
          height: 100%;
        }
      }
      .fbVideoUrl {
        width: 100%;
        height: 400px;
        video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.opera {
  width: 107px;
  line-height: 50px;
  height: 50px;
  text-align: center;
  background: #1874fd;
  border-radius: 6px;
  font-size: 18px;
  color: #fff;
}
//

.badge {
  width: 42px;
  height: 42px;
  background: #1cd49e;
  margin: 0 auto;
  line-height: 42px;
  color: #fff;
  border-radius: 50%;
  margin-bottom: 15px;
  font-size: 26px;
}

.dialog-content {
  margin-top: 20px;
  color: #2f3a4f;
  font-size: 18px;
  text-align: center;
}
</style>
